<template>
  <div class="course_question_block">
    <div class="course_question_container">
      <div class="course_question_title">
        Вопрос - Ответ
      </div>
      <div class="course_question_slider_container">
        <div class="courses_question_arrow_left" @click="prevPage"/>
        <div class="courses_question_arrow_right" @click="nextPage"/>
        <carousel
            :paginationEnabled="false"
            :per-page="1"
            navigationNextLabel="next"
            :navigateTo="manualNavigation">
          <slide>
            <div class="course_question_slider_item">
              <div class="course_question_slide">
                <div class="course_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_answer_desc">
                    <div class="course_answer_name">Нурбаева Салтанат</div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_best_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_best_answer_desc">
                    <div class="course_best_answer_block">
                      <div class="course_best_answer_name">Ответ от: <span>Жанабаева Гульмира</span></div>
                      <div class="course_best_answer_label">Лучший ответ</div>
                    </div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_question_all_answers"><span>Посмотреть все ответы</span></div>
              </div>
            </div>
            <div class="course_question_slider_item">
              <div class="course_question_slide">
                <div class="course_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_answer_desc">
                    <div class="course_answer_name">Нурбаева Салтанат</div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_best_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_best_answer_desc">
                    <div class="course_best_answer_block">
                      <div class="course_best_answer_name">Ответ от: <span>Жанабаева Гульмира</span></div>
                      <div class="course_best_answer_label">Лучший ответ</div>
                    </div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_question_all_answers"><span>Посмотреть все ответы</span></div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="course_question_slider_item">
              <div class="course_question_slide">
                <div class="course_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_answer_desc">
                    <div class="course_answer_name">Нурбаева Салтанат</div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_best_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_best_answer_desc">
                    <div class="course_best_answer_block">
                      <div class="course_best_answer_name">Ответ от: <span>Жанабаева Гульмира</span></div>
                      <div class="course_best_answer_label">Лучший ответ</div>
                    </div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_question_all_answers"><span>Посмотреть все ответы</span></div>
              </div>
            </div>
            <div class="course_question_slider_item">
              <div class="course_question_slide">
                <div class="course_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_answer_desc">
                    <div class="course_answer_name">Нурбаева Салтанат</div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_best_answer">
                  <div class="course_answer_photo"></div>
                  <div class="course_best_answer_desc">
                    <div class="course_best_answer_block">
                      <div class="course_best_answer_name">Ответ от: <span>Жанабаева Гульмира</span></div>
                      <div class="course_best_answer_label">Лучший ответ</div>
                    </div>
                    <div class="course_answer_text">Вы подключаетесь к уроку из Личного кабинета по кнопке «Войти» и
                      начинаете выполнять задания на грамматику, чтение, аудирование, учите новые слова. «Smart Ustaz»
                      объединяет в себе сервисы, позволяющие учителям проходить курсы по развитию компетенций и навыков
                      soft skills в онлайн формате.
                    </div>
                  </div>
                </div>
                <div class="course_question_all_answers"><span>Посмотреть все ответы</span></div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  props: [],
  name: 'CourseReviews',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      manualNavigation : 0,
    }
  },
  methods: {
    nextPage() {
      if (this.manualNavigation >= 3) {
        this.manualNavigation = 0
      } else this.manualNavigation = this.manualNavigation + 1
    },
    prevPage() {
      if (this.manualNavigation <= 0) {
        this.manualNavigation = 3
      } else this.manualNavigation = this.manualNavigation - 1
    },
    setActive(id) {
      this.manualNavigation = id
    }
  },
}
</script>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
.course_block .course_reviews_block .course_reviews_container .course_reviews_names .course_reviews_names_item .course_reviews_names_photo.base64{
  background-size: 105%;
}
.course_block .course_reviews_block .course_reviews_container .course_reviews_slider .course_reviews_photo_block.base64{
  background-size: 130%;
}
.VueCarousel-slide{
  display: flex;
}
</style>